import React, { Fragment } from 'react';
import { Spacer } from '../../../atoms/position/spacer';
import { styled } from 'buttered';

let Wrapper = styled('footer')`
  display: flex;
  position: sticky;
  bottom: 0px;
  padding: 0px 20px 20px 20px;
  background: var(--vapor-background);

  .action {
    display: flex;
    flex-grow: 1;

    & > * {
      flex-grow: 1;
    }
  }
`;

export let ModalActions = ({
  children,
  style,
  className
}: {
  children: React.ReactNode;
  style: React.CSSProperties;
  className: string;
}) => {
  let childArray = Array.isArray(children) ? children : [children];

  return (
    <Wrapper style={style} className={className}>
      {childArray.map((c, i) => (
        <Fragment key={i}>
          <div className="action">{c}</div>

          {i != childArray.length - 1 && <Spacer width="15px" />}
        </Fragment>
      ))}
    </Wrapper>
  );
};
