import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useToggleState } from '@react-stately/toggle';
import { useFocusRing } from '@react-aria/focus';
import { useCheckbox } from '@react-aria/checkbox';

import React, { useRef } from 'react';
import { styled } from 'buttered';
import { classNames } from '../../core/classNames';
import { Check } from 'react-feather';
import { mergeProps } from '@react-aria/utils';

let Wrapper = styled('span')`
  height: 20px;
  width: 20px;
  background: var(--vapor-accent-3);
  border-radius: var(--vapor-radius-small);
  position: relative;
  border: solid transparent 1px;
  transition: all 0.3s;

  &.focussed {
    border: solid var(--vapor-primary) 1px;
  }

  &.isSelected {
    background: var(--vapor-accent-9);
  }
`;

let Icon = styled('span')`
  display: flex;
  opacity: 0;
  transform: rotate(-10deg);
  transition: all 0.3s;
  padding-top: 2px;
  padding-left: 3px;

  svg {
    margin-top: 1px;
    height: 13px;
    width: 13px;
  }

  &.isSelected {
    opacity: 1;
    transform: rotate(0deg);
    color: var(--vapor-background);
  }
`;

let Label = styled('label')`
  display: flex;
  align-items: center;

  &.disabled {
    opacity: 0.7;
  }
`;

let LabelText = styled('span')`
  padding-left: 10px;
  font-size: 14px;
  user-select: none;
`;

export let Checkbox = (props: {
  onChange: (selected: boolean) => undefined;
  isSelected?: boolean;
  defaultSelected?: boolean;
  label: string;
  hideLabel?: boolean;
  disabled?: boolean;
}) => {
  let state = useToggleState(props);
  let ref = useRef();
  let { inputProps } = useCheckbox({ ...props, 'aria-label': props.label }, state, ref);
  let { isFocusVisible, focusProps } = useFocusRing();

  return (
    <Label
      className={classNames({
        disabled: props.disabled
      })}
    >
      <VisuallyHidden>
        <input
          {...mergeProps(inputProps, focusProps, { disabled: props.disabled })}
          ref={ref}
        />
      </VisuallyHidden>

      <Wrapper
        aria-hidden="true"
        className={classNames({
          focussed: isFocusVisible,
          isSelected: state.isSelected
        })}
      >
        <Icon className={classNames({ isSelected: state.isSelected })}>
          <Check style={{ strokeWidth: 2.5 }} />
        </Icon>
      </Wrapper>

      {!props.hideLabel && <LabelText>{props.label}</LabelText>}
    </Label>
  );
};
