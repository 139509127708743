import React, { useMemo } from 'react';
import capitalize from '@varld/capitalize';
import { classNames } from '../../core/classNames';
import { useCss } from '../../hooks/css';

type Tags =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'span'
  | 'p'
  | 'small'
  | 'label'
  | 'div'
  | 'strong';

let truncateStyles = `
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  max-width: 100%;
  min-width: 0;
`;

let baseCss = `
  color: var(--vapor-foreground);
`;

let getElement = (
  type: Tags,
  props: {
    className: string;
    style: React.CSSProperties;
  },
  children: React.ReactNode
) => React.createElement(type, props, children);

export let Text = ({
  children,
  as: asTag,
  weight,
  size,
  lineHeight,
  color,
  transform,
  className,
  style,
  truncate,
  preset
}: {
  children: React.ReactNode;
  as?: Tags;
  weight?: number;
  size?: number;
  lineHeight?: number;
  color?: string;
  transform?: 'capitalize' | 'uppercase' | 'lowercase';
  className?: string;
  style?: React.CSSProperties;
  truncate?: boolean | number;
  preset?: 'title' | 'bigTitle' | 'heading' | 'section' | 'description' | 'content';
}) => {
  if (!asTag) {
    if (!weight || weight < 600) asTag = 'p';
    else asTag = 'strong';
  }

  if (!size) size = 14;
  if (!weight) {
    if (size > 22) weight = 600;
    else if (size > 44) weight = 600;
    else weight = 400;
  }
  if (!lineHeight) lineHeight = 1.15;

  // normalize weight
  weight = Math.round(weight / 100) * 100;

  if (preset == 'title') {
    asTag = 'h1';
    weight = 700;
    size = 30;
  } else if (preset == 'bigTitle') {
    asTag = 'h1';
    weight = 700;
    size = 50;
  } else if (preset == 'heading') {
    asTag = 'h1';
    weight = 700;
    size = 26;
  } else if (preset == 'section') {
    asTag = 'h2';
    weight = 600;
    size = 20;
  } else if (preset == 'description') {
    asTag = 'p';
    weight = 500;
    size = 16;
  } else if (preset == 'content') {
    asTag = 'p';
    weight = 400;
    size = 16;
  }

  let styles: React.CSSProperties = {
    fontSize: size + 'px',
    fontWeight: weight,
    color: color,
    lineHeight: lineHeight,
    ...style
  };

  let css = useMemo(() => {
    let css = baseCss;

    if (truncate === true) css += truncateStyles;

    if (typeof truncate == 'number') {
      css += `
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: ${truncate};
      -webkit-box-orient: vertical;`;
    }

    return css;
  }, [truncate]);

  let styleClass = useCss(css);

  if (transform && typeof children == 'string') {
    if (transform == 'capitalize') children = capitalize(children);
    if (transform == 'lowercase') children = children.toString().toLowerCase();
    if (transform == 'uppercase') children = children.toString().toUpperCase();
  }

  return getElement(
    asTag,
    { style: styles, className: classNames('text', className, styleClass) },
    children
  );
};
